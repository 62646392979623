<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-calendar"></i> 表单
                </el-breadcrumb-item>
                <el-breadcrumb-item>图片上传</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="content-title">支持拖拽</div>
            <div class="plugins-tips">
                Element UI自带上传组件。
                访问地址：
                <a
                    href="http://element.eleme.io/#/zh-CN/component/upload"
                    target="_blank"
                >Element UI Upload</a>
            </div>
            <el-upload
                class="upload-demo"
                drag
                action="http://jsonplaceholder.typicode.com/api/posts/"
                multiple
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    将文件拖到此处，或
                    <em>点击上传</em>
                </div>
                <template #tip>
                    <div class="el-upload__tip">只能上传 jpg/png 文件，且不超过 500kb</div>
                </template>
            </el-upload>

            <div class="content-title">支持裁剪</div>
            <div class="plugins-tips">
                vue-cropperjs：一个封装了 cropperjs 的 Vue 组件。
                访问地址：
                <a
                    href="https://github.com/Agontuk/vue-cropperjs"
                    target="_blank"
                >vue-cropperjs</a>
            </div>
            <div class="crop-demo">
                <img :src="cropImg" class="pre-img" />
                <div class="crop-demo-btn">
                    选择图片
                    <input
                        class="crop-input"
                        type="file"
                        name="image"
                        accept="image/*"
                        @change="setImage"
                    />
                </div>
                <ul class="upload-photo">
                    <!-- 最多上传一张图片 -->
                    <li @mouseover="mouseoverImg()" @mouseout="mouseoutImg()">
                        <img ref="img1" src="../assets/img/avatar.png" @click="uploadPhoto" />
                        <div ref="deleteImg1" class="delete-img">
                        <i class="el-icon-delete" @click="deleteImage"></i>
                        </div>
                    </li>
                </ul>
                <input type="file" hidden ref="photoFile" @change="fileChange" style="display: none;" />
            </div>

            <el-dialog title="裁剪图片" v-model="dialogVisible" width="600px">
                <!-- 1.截图区域 -->
                <vue-cropper
                    ref="cropper"
                    :src="imgSrc"
                    :ready="cropImage"
                    :zoom="cropImage"
                    :cropmove="cropImage"
                    :aspectRatio="1"
                    style="width:100%;height:300px;"
                    :autoCrop="true"
                    :wheelZoomRatio="true"
                ></vue-cropper>
                <!-- <img ref="cropper" style="width:100%;height:300px;" src=""/> -->
                <!-- 2.操作按钮区域 -->
                <div class="btn-content">
                    <input class="btn" type="button" value="+" title="放大" @click="zoomOut" />
                    <input class="btn" type="button" style value="-" title="缩小" @click="zoomIn" />
                    <input class="btn" type="button" value="↺" title="左旋转" @click="rotateLeft" />
                    <input class="btn" type="button" value="↻" title="右旋转" @click="rotateRight" />
                    <input class="btn" type="button" value="↓" title="下载" @click="down('blob')" />
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="cancelCrop">取 消</el-button>
                        <el-button type="primary" @click="submitPhoto">确 定</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
// import Cropper from "vue-cropperjs";
// import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import {elConfirmDialog, IsNullOrEmpty, base64ToFile} from '@/common/js/comm'
import { ElMessageBox } from 'element-plus';
import lrz from 'lrz'
import { postimg } from "@/serviceApi/Image/Image";
export default {
    name: "upload",
    // props:{

    // },
    data() {
        return {
            defaultSrc: '',//require("../assets/img/img.jpg"),
            fileList: [],
            imgSrc: "",
            cropImg: "",
            dialogVisible: false,
            cropper: null,

            // uploadImage用于存放“添加照片”图片base64
            uploadImage: "",//默认静态图片
            //剪切图片上传
            previews: {},
            option: {
                img: "",
                outputSize: 1, //剪切后的图片质量（0.1-1）
                full: false, //输出原图比例截图 props名full
                outputType: "png",
                canMove: true,
                original: false,
                canMoveBox: true,
                autoCrop: true,
                autoCropWidth: 150,
                autoCropHeight: 150,
                fixedBox: true
            },
            downImg: "#",
            fileInfo: null,
            aspectRatio: 1,
            dragMode: "move"
        };
    },
    components: {
        VueCropper
    },
    methods: {
        setImage(e) {
            const file = e.target.files[0];
            if (!file.type.includes("image/")) {
                return;
            }
            const reader = new FileReader();
            reader.onload = event => {
                this.dialogVisible = true;
                this.imgSrc = event.target.result;
                this.$refs.cropper &&
                this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
        },
        cropImage() {
            var that = this;
            this.$refs.cropper.getCroppedCanvas().toBlob(function (blob) {   
                let croppedImage = new File([blob],that.fileInfo.name,{type:blob.type},{ lastModified: Date.now()});
                that.fileInfo = croppedImage;
            });
            this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        cancelCrop() {
            this.dialogVisible = false;
            this.cropImg = this.defaultSrc;
        },
        imageuploaded(res) {
            console.log(res);
        },
        handleError() {
            this.$notify.error({
                title: "上传失败",
                message: "图片上传接口上传失败，可更改为自己的服务器接口"
            });
        },
        deleteImage(){
            let that = this;
            elConfirmDialog(ElMessageBox, "是否删除该照片?","删除图片", "warning", 
            function(){
                // let param = {
                //   token: this.$store.state.token,
                //   imageId: id
                // };
                // iamgeDelete(param).then(res => {
                //   console.log(" 提交删除:", res);
                //   if (res.data.respCode == "00000") {
                //     this.$message.success(res.data.respDesc);
                //   } else {
                //     this.$message.error(res.data.respDesc);
                //   }
                //   this.reload();
                // });
                that.$refs.img1.src = that.uploadImage.src;
                that.$message({
                    message: "删除成功!",
                    type: "success"
                });
            },
            function(){
                that.$message({
                    message: "已取消删除",
                    type: "info"
                });
            });
        },
        // 鼠标移入图像
        mouseoverImg() {
            this.displayControl(this.$refs.img1, this.$refs.deleteImg1);
        },
        displayControl(img, deleteImg) {
            if (img.src == this.uploadImage) {
                deleteImg.style.display = "none";
            } else {
                deleteImg.style.display = "block";
            }
        },
        // 鼠标移出图像--删除图标隐藏
        mouseoutImg() {
            this.$refs.deleteImg1.style.display = "none";
        },
        // 本地上传头像
        uploadPhoto() {
            this.$refs.photoFile.click();
        },
        // 上传图片
        fileChange(e) {
            let file = this.$refs.photoFile.files[0];
            this.fileInfo = file;
            let that = this;
            if (/.(png|jpg|jpeg)$/.test(file.name)) {
                // let url = URL.createObjectURL(file)
                let fr = new FileReader();
                fr.readAsDataURL(file);
                fr.onload = e => {
                    // that.option.img = e.target.result;
                    // that.cropImageFormVisible = true;
                    // that.$refs.photoFile.value = "";
                    // console.log(that.Handler)
                    // that.$set(that.modal, "base64", e.target.result.split(",")[1]);

                    that.dialogVisible = true;
                    that.imgSrc = e.target.result;
                    that.$refs.cropper && that.$refs.cropper.replace(e.target.result);
                    that.$refs.photoFile.value = "";
                };
            } else {
                this.$message({
                    message: "请选择符合格式要求的图片",
                    type: "warning"
                });
                this.$refs.photoFile.value = "";
                this.fileInfo = null;
            }
        },
        // 上传图像
        submitPhoto() {
            var file = this.fileInfo;
            let that = this;
            if (IsNullOrEmpty(file)){
                this.$message({
                    message: "图片不能为空！",
                    type: "error"
                });
                return;
            }
            if(file.size > 10000000){
               return this.$message({
                    message: "图片超出大小,请压缩后再上传！",
                    type: "error"
                });
            }
            var formData = new FormData();
            if (file.size > 5000000){
                lrz(file, {
                    fileName: file.name,
                    quality: 0.5 //设置压缩质量
                }).then((res)=>{
                    let tepm_file = new window.File([res.file], file.name,{type:file.type});
                    formData.append("File", tepm_file);
                    formData.append("FileCategory", "Avatar");
                    postimg(formData).then(function(res) {
                        if (1 == res.code) {
                            that.$refs.img1.src = res.data.url;
                            that.dialogVisible = false;
                        } else {
                            that.$message({
                                message: res.msg,
                                type: "error"
                            });
                        }
                    });
                });
            } else {
                formData.append("File", file);
                formData.append("FileCategory", "Avatar");
                postimg(formData).then(function(res) {
                    if (1 == res.code) {
                        that.$refs.img1.src = res.data.url;
                        that.dialogVisible = false;
                    } else {
                        that.$message({
                            message: res.msg,
                            type: "error"
                        });
                    }
                });
            }

        },
        // 放大
        zoomOut(){
            this.$refs.cropper.zoom(0.1);
        },
        // 缩小
        zoomIn(){
            this.$refs.cropper.zoom(-0.1);
        },
        //左旋转
        rotateLeft(){
            this.$refs.cropper.rotate(-45);
        },
        //右旋转
        rotateRight(){
            this.$refs.cropper.rotate(45);
        },
        //下载
        down(type){
            var aLink = document.createElement("a");
            aLink.download = "author-img";
            if (type === "blob") {
                this.$refs.cropper.getCroppedCanvas().toBlob(data => {
                    this.downImg = window.URL.createObjectURL(data);
                    aLink.href = window.URL.createObjectURL(data);
                    aLink.click();
                });
            } else {
                this.$refs.cropper.getCropData(data => {
                    this.downImg = data;
                    aLink.href = data;
                    aLink.click();
                });
            }
        },
    },
    created() {
        this.cropImg = this.defaultSrc;
    },
    mounted(){
        this.uploadImage = this.$refs.img1;
    }
};
</script>

<style lang="less">
    .upload-photo{
        li {
          width: 180px;
          height: 180px;
          margin-right: 15px;
          position: relative;
          .delete-img {
            position: absolute;
            width: 180px;
            height: 40px;
            line-height: 40px;
            left: 0px;
            top: 140px;
            background: rgba(59, 60, 61, 0.5);
            // box-sizing: content-box;
            z-index: 999;
            cursor: pointer;
            text-align: right;
            i {
              margin: 8px 10px 0 0;
              display: block;
              font-size: 24px;
              color: white;
            }
          }
        }
        img {
          border: 1px dashed #d9d9d9;
          border-radius: 5px;
          box-sizing: border-box;
          width: 180px;
          height: 180px;
          margin-top: 0px;
          &:hover {
            border: 1px dashed #409eff;
          }
        }
    }
          // 2.操作按钮区域
      .btn-content {
        width: 350px;
        margin: 10px 20px;
        text-align: center;
        margin: 10px auto;
        // #uploads {
        //   position: absolute;
        //   clip: rect(0 0 0 0);
        // }
        .btn {
          height: 32px;
          width: 32px;
          font-size: 20px;
          margin: 3px 5px;
          background-color: #fff;
          border: 1px solid #999;
          border-radius: 4px;
          cursor: pointer;
        }
      }
</style>